<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-text-field
      id="conjuntos-search"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mt-4 break-search"
      label="Procurar"
      hide-details
      single-line
      style="max-width: 250px"
    />
    <v-divider class="mt-10" />
    <v-data-table
      :headers="headers"
      :items="conjuntos"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      no-data-text="Nenhum conjunto disponível"
    >
      <template v-slot:[`item.nome`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="text-truncate-column"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.nome }}
            </div>
          </template>
          <span>{{ item.nome }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.descr`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="text-truncate-column"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.descr }}
            </div>
          </template>
          <span>{{ item.descr }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ConjuntosService from '@/services/ConjuntosService';

export default {
  name: 'ConjuntosDataTable',

  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    }
  },

  data: () => ({
    search: '',
    conjuntos: [],
    loading: false,
    headers: [
      {
        text: 'COD_ID',
        value: 'cod_id'
      },
      {
        text: 'NOME',
        value: 'nome'
      },
      {
        text: 'SIST_INTE',
        value: 'sist_inte'
      },
      {
        text: 'SIST_SUBT',
        value: 'sist_subt'
      },
      {
        text: 'DESCR',
        value: 'descr'
      }
    ]
  }),

  created() {
    this.getConjuntos();
  },

  methods: {
    getConjuntos() {
      this.loading = true;
      ConjuntosService.getConjuntos(this.month, this.bdgdVersion)
        .then((response) => {
          this.conjuntos = response.data;
        })
        .catch(() =>
          this.$toast.error(
            'Erro ao recuperar os conjuntos elétricos da BDGD.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => (this.loading = false));
    }
  },

  watch: {
    month() {
      this.getConjuntos();
    },
    bdgdVersion() {
      this.getConjuntos();
    },
    conjuntos() {
      this.$emit('itemsChanged', {
        items: this.conjuntos,
        numItems: this.conjuntos.length
      });
    }
  }
};
</script>
