var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"conjuntos-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.conjuntos,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhum conjunto disponível"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.nome)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.nome))])])]}},{key:"item.descr",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.descr)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.descr))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }